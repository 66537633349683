import AppRowContainer from '@erkport/components/AppRowContainer';
import {getLastMonths} from '@erkport/helpers/DateHelper';
import {Col} from 'antd';
import React, {useEffect, useState} from 'react';
import moment from '@erkport/services/moment';
import dataGroup1 from '@erkport/mockapi/fakedb/dataGroup2';
import {useQuery} from 'react-query';
import {getDataGroup2} from '@erkport/services/erkport';
import Loading from '@erkport/components/AppLoadable/Loading';
import AppCard from '@erkport/components/AppCard';
import CardContainer from '../cardContainer';
import Last12MonthsChart from '../../last12MonthsChart';
import TrendTable from '../trendTable';

const currentLast12Months = getLastMonths(
  Number(moment().format('Y')),
  Number(moment().format('M')),
  12,
);

const prevLast12Months = getLastMonths(
  Number(moment().format('Y')) - 1,
  Number(moment().format('M')),
  12,
);

const filterData = (
  data: any[],
  year: number,
  month: number,
  source: string,
) => {
  const monthData = data?.filter(
    (x) => Number(x.year) == year && Number(x.month) == month,
  );
  const filteredData =
    monthData?.reduce((acc, cur) => {
      acc[cur[source]] = {
        totalSalesEur:
          (acc[cur[source]]?.totalSalesEur || 0) + cur.totalSalesEur,
        totalProfitEur:
          (acc[cur[source]]?.totalProfitEur || 0) + cur.totalProfitEur,
        profitability:
          (acc[cur[source]]?.profitability || 0) + cur.profitability,
      };
      return acc;
    }, {}) || [];

  return Object.entries(filteredData).map(([key, value]) => ({
    [source]: key,
    ...(value as object),
  }));
};

type TrendBaseType = {
  source: string;
};

const TrendBase: React.FC<TrendBaseType> = ({source: _source}) => {
  const [selectedCard, setSelectedCard] = useState({type: 'all', data: null});
  const [selectedRow, setSelectedRow] = useState(null);
  const [data, setData] = useState(null);
  const [source, setSource] = useState(
    _source === 'port' ? 'fromPort' : _source,
  );

  useEffect(() => {
    getDataGroup2().then((x) => setData(x));
  }, []);

  const currentData = currentLast12Months.map((x) => ({
    year: x.year,
    month: x.month,
    data: filterData(data, x.year, x.month, source),
  }));
  const prevData = prevLast12Months.map((x) => ({
    year: x.year,
    month: x.month,
    data: filterData(data, x.year, x.month, source),
  }));

  return !data ? (
    <Loading />
  ) : (
    <>
      <AppRowContainer>
        <Col xs={24}>
          <CardContainer
            data={currentData}
            selectedCardType={selectedCard?.type}
            setSelectedCard={setSelectedCard}
            source={source}
          />
        </Col>
      </AppRowContainer>
      <AppRowContainer>
        <Col xs={24}>
          <AppCard title={selectedRow}>
            <Last12MonthsChart
              containerName={`${source.toLocaleLowerCase()}-last-12-months`}
              height={200}
              legends={['Satış', 'Kar', 'Geçmiş Satış', 'Geçmiş Kar']}
              data={{
                current: currentData.map((c) => ({
                  x: c.month,
                  ...c.data.find((x) => x[source] == selectedRow),
                })),
                prev: prevData.map((c) => ({
                  x: c.month,
                  ...c.data.find((x) => x[source] == selectedRow),
                })),
              }}
            />
          </AppCard>
        </Col>
      </AppRowContainer>
      <AppRowContainer>
        <TrendTable
          data={currentData}
          last12Months={currentLast12Months}
          selectedCard={selectedCard}
          source={source}
          setSource={setSource}
          setSelectedRow={setSelectedRow}
          selectedRow={selectedRow}
        />
      </AppRowContainer>
    </>
  );
};

export default TrendBase;
