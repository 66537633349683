import React from 'react';
import VesselStoryPage from './page';
import {getVessels} from '@erkport/services/erkport';
import PageWrapper from '@erkport/components/PageWrapper';

const VesselStory = () => {
  return (
    <>
      <PageWrapper
        vesselsProvider={getVessels}
        hasDate
        dateConfig={{allowClear: false, labelKey: 'common.finishingDate'}}
        hasLoadUnit
      >
        {(props) => <VesselStoryPage {...props} />}
      </PageWrapper>
    </>
  );
};

export default VesselStory;
